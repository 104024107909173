import './App.css';
import React from 'react';
import { FaInstagram, FaTwitter, FaGithub, FaLinkedin } from 'react-icons/fa';
import { FaMastodon, FaYoutube, FaCloud } from 'react-icons/fa';
import linksData from './linksData';
import profilePicture from './profilePicture.png';

const IconMap = {
  FaInstagram,
  FaTwitter,
  FaGithub,
  FaLinkedin,
  FaMastodon,
  FaYoutube,
  FaCloud,
};

const Link = ({ link }) => {
  const Icon = IconMap[link.icon];

  return (
    <a href={link.url} target="_blank" rel="noopener noreferrer">
      <Icon />
      <span>{link.name}</span>
    </a>
  );
};

const App = () => {
  return (
    <div className="container">
      <div className="profile-container">
        <img src={profilePicture} alt="Profile" className="profile-picture" />
        <h1 className="profile-name">Gunnar Grosch</h1>
        <p className="profile-bio">Developer Advocate at Amazon Web Services (AWS). Based in Sweden. Focused on resilience, chaos engineering, and modern applications.</p>
      </div>
      <div className="links-container">
        {linksData.map((link, index) => (
          <Link key={index} link={link} />
        ))}
      </div>
      <footer className="footer">
        &copy; {new Date().getFullYear()} Gunnar Grosch. All rights reserved.
      </footer>
    </div>
  );
};

export default App;
